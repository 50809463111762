import { useRouter } from 'next/router';
import { SWRConfig } from 'swr';

import { withStaticProps } from '~/lib/static';
import { getDeals, extractBrowse } from '~/lib/browse/fetch';
import useAttributes from '~/lib/attributes/hook';
import { fetchJson } from '~/lib/fetch';
import { useBeacon } from '~/lib/script';
import { DEALATTRIBUTE_PERSONAL_URL } from '~/lib/attributes/constants';

import Browse from '~/components/browse/Browse';
import { BrowseSeo } from '~/components/seo';

const BROWSE_PATHS_FOR_EXP = [
  'browse',
  'search',
  'software',
  'creative-resources',
  'templates',
  'courses-learning',
  'courses-more',
];

// Only use the first element of the slug to check if it's one of the experiment paths
// The rest of the slug is used to check they are all query params
// router.query.slug also includes query params ['!!queryparam'] so we can just filter here
const isEnrollBrowseExperiment = (slug) => {
  if (!slug?.length > 0) return false;

  const doesPathMatch = BROWSE_PATHS_FOR_EXP.some((path) => path === slug[0]);
  const queryParams = slug.slice(1);

  return doesPathMatch && queryParams.every((path) => path.startsWith('!!'));
};

export const getStaticProps = withStaticProps(async ({ params }) => {
  const { slug } = params;
  const attributes = await fetchJson(DEALATTRIBUTE_PERSONAL_URL);

  let initialFilters = null;
  try {
    initialFilters = extractBrowse({ slug, dealAttributes: attributes });
  } catch (error) {
    return { notFound: 404 };
  }

  // if page is greater than N we will load the first N pages and then load the rest in the client
  const deals = await getDeals(initialFilters);

  return {
    props: {
      fallbackData: [deals],
      isCollectionBrowsePage: true, // used to clear search (initially) if not on this page
      fallback: {
        [DEALATTRIBUTE_PERSONAL_URL]: attributes,
      },
      // Check if the slug (url) is one of the experiment paths
      enrollBrowseExperiment: isEnrollBrowseExperiment(slug),
    },
    revalidate: 240,
  };
});

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export default function BrowsePage({ fallback = {}, fallbackData }) {
  const router = useRouter();
  // We just need to enroll the user, nothing else
  // Only enroll if the path is one of the experiment paths
  useBeacon();

  const { data: dealAttributes } = useAttributes({
    fallbackData: fallback[DEALATTRIBUTE_PERSONAL_URL],
  });

  const initialFilters = extractBrowse({
    slug: router.query.slug,
    dealAttributes,
    query: router.query,
  });

  const { attributes = {} } = initialFilters;

  const isSearching = !!initialFilters.query;

  const showBreadcrumbs = attributes.category && !isSearching;

  return (
    <SWRConfig
      value={{
        fallback,
      }}
    >
      <BrowseSeo
        group={attributes.group}
        category={attributes.category}
        subcategory={attributes.subcategory}
      />
      <Browse
        initialFilters={initialFilters}
        fallbackData={fallbackData}
        showTitle
        showBreadcrumbs={showBreadcrumbs}
      />
    </SWRConfig>
  );
}
